<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">Buyurtmalar ro'yxati</h3>
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :loading="isLoading"
                :headers="headers"
                :items="getData"
                no-data-text="Malumot kiritilmagan"
                item-key="name"
                loading-text="Malumot yuklanmoqda..."
                hide-default-footer
                class="elevation-1"
              >
                <!-- <template v-slot:[`item.item`]="{ item }">
        <span
          v-for="(dest, index) in item.direction_truck.direction
            .citydestination"
          :key="dest.id"
        >
          {{ dest.name }}
          <span
            v-if="
              index == item.direction_truck.direction.citydestination.length - 1
            "
          ></span>
          <span v-else>-</span>
        </span>
      </template> -->
                <template v-slot:[`item.zayavka_owner`]="{ item }">
                  <div class="text-black">
                    {{ item.zayavka_owner }}
                  </div>
                </template>
                <template v-slot:[`item.contract`]="{ item }">
                  <div class="text-black">
                    {{ item.contract }}
                  </div>
                </template>
                <template v-slot:[`item.deliver`]="{ item }">
                  <div class="text-black" v-if="item.deliver == 'POS'">
                    Postavshik
                  </div>
                  <div class="text-black" v-else>BTS</div>
                </template>

                <template v-slot:[`item.price_with_nds`]="{ item }">
                  <div class="text-black">
                    {{ item.price_with_nds }}
                  </div>
                </template>
                <template v-slot:[`item.truck`]="{ item }">
                  <div v-if="item.truck">
                    {{ item.truck.reg_number_region }}
                    {{ item.truck.reg_number }}
                  </div>
                </template>
                <template v-slot:[`item.start_date_new`]="{ item }" to="/">
                  <div class="text-black">
                    {{ item.start_date_new | formatDate }}
                  </div>
                </template>
                <template v-slot:[`item.location`]="{ item }">
                  <div v-if="item.deliver !== 'POS' && item.truck !== null">
                    <router-link
                      target="_blank"
                      :to="'/zayavka/map/' + item.truck_img_id"
                    >
                      <div class="warning status__name click">
                        <v-icon size="18" color="white">mdi-map-marker</v-icon>
                        GPS
                      </div>
                    </router-link>
                  </div>
                  <div v-else class="info status__name">
                    <v-icon size="18" color="white">mdi-map-marker</v-icon>
                    GPS
                  </div>
                </template>
                <template v-slot:[`item.direction`]="{ item }">
                  <span
                    v-for="(city, index) in item.direction_cities"
                    :key="city.id"
                  >
                    {{ city.city_name.name }}
                    <i v-if="index + 1 !== item.direction_cities.length">-</i>
                  </span>
                </template>
                <template v-slot:[`item.zayavka_status_name`]="{ item }">
                  <div :class="item.status_style" class="status__name">
                    {{ item.zayavka_status_name }}
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <action :index="item"></action>
                </template>
                <template v-slot:[`item.price_with_nds`]="{ item }">
                  {{ item.price_with_nds.toLocaleString('zh-ZH') }}
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import action from './action'
export default {
  data() {
    return {
      page: 1,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id'
        },
        { text: 'Mijoz', value: 'zayavka_owner' },
        { text: 'Shartnoma #', value: 'contract_number' },
        { text: 'Postavshik', value: 'deliver' },
        { text: 'Narxi', value: 'price_with_nds' },
        { text: 'Mashina raqami', value: 'truck' },
        { text: 'Boshlanish', value: 'start_date_new' },
        { text: 'Lokatsiya', value: 'location' },
        { text: "Yo'nalish", value: 'direction' },
        { text: 'Status', value: 'zayavka_status_name' },
        { text: null, value: 'action', sortable: false }
      ]
    }
  },
  components: {
    action
  },
  created() {
    this.$store.dispatch('getClientPageZayavka')
  },
  computed: {
    getData() {
      return this.$store.state.clientStore.clientPageZayavka.results
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Buyurtmalar' }])
  }
}
</script>

<style scoped>
.text-black {
  color: #000;
}
.click {
  cursor: pointer;
}
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
